<template>
 <div class="container-fliud" id="ghana">
   <Header></Header>
  <div style="margin-top:130px">
  
    <div class="gray" >
                        <h4 style="color:#97c149; margin-left:100px; margin-top:40px">
                            {{detail.country}}
                        </h4> 
                      
                      <div class="container">
                        <div class="row">
                        <div class="col-md-12"  style="background-color:#fff; padding:10px" id="tab">
                          <ul class="nav nav-tabs" style="color: #707070;">
                            <li class="nav-item"  :class="[activeTab === 'activities' ? 'active' :'']">
                              <div class="nav-link"  aria-current="page" @click="activeTab ='activities'" style="color: #707070;">
                                <div class="row">
                                  <div class="col-md-2">
                                    <p style="font-size:35px">1</p>
                                    </div>
                                  <div class="col-md-10">
                                  <p style="font-size:18px"> Activity</p>
                                  <p style="font-size:12px">Themes Activity</p>
                                  </div>
                                </div>
                                </div>
                              <!-- <small class="nav-link active" style="background-color:#fff">Theme Activities</small> -->
                            </li>

                              <li class="nav-item"  :class="[activeTab === 'gallery' ? 'active' :'']">
                              <div class="nav-link"   @click="activeTab ='gallery'" style="color: #707070;">
                                <div class="row">
                                  <div class="col-md-2">
                                    <p style="font-size:35px">2</p>
                                    </div>
                                  <div class="col-md-10">
                                  <p style="font-size:18px"> Gallery</p>
                                  <p style="font-size:12px">Image Gallery</p>
                                  </div>
                                </div>
                                </div>
                            </li>
                            <li class="nav-item" :class="[activeTab === 'istory' ? 'active' :'']">
                              <div class="nav-link"  @click="activeTab ='istory'" style="color: #707070;">
                                <div class="row">
                                  <div class="col-md-2">
                                    <p style="font-size:35px">3</p>
                                    </div>
                                  <div class="col-md-10">
                                  <p style="font-size:18px"> iStory</p>
                                  <p style="font-size:12px">Motivating Stories</p>
                                  </div>
                                </div>
                                </div>
                            </li>
                          </ul>
                            <div class="tab-content" style="background-color:#fff; margin-top:50px">
                                   <div class="activity"  v-if="activeTab === 'activities'">
                                    <div class="row" style="margin-bottom:10px">
                                      <div class="col-md-12">
                                         <select @change="sort($event)" class="form-control">
                                            <option value="">Filter by</option>
                                            <option value="Objective 1">Objective 1</option>
                                            <option value="Objective 2">Objective 2</option>
                                            <option value="Objective 3">Objective 3</option>
                                            <option value="Objective 4">Objective 4</option>
                                        </select>
                                       
                                      </div>
                                    </div>
                                  <div id="accordion" >
                                    
                                  <div class="card" v-for="(activity, index) in activitiesData" :key="activity.id"  style="background-color:#fff; margin:10px; border-radius:10px;box-shadow: 0px 0px 30px -23px rgba(34,32,32,0.75);"  >
                                    <div class="card-header" id="headingOne" style="background-color:#fff; border-radius:10px;box-shadow: 0px 0px 30px -23px rgba(34,32,32,0.75);">
                                     
                                        <router-link :to="'/activitytrainings/' + activity.id" class="btn btn-link"   
                                         
                                          style="width:1050px;background-color:#fff; text-decoration:none">
                                          <div class="row">
                                                <div class="col-md-1">
                                                <img src="../../public/images2/calen.png" alt="" style="width:50px">
                                              </div>
                                              <div class="col-md-10" style="color:#a9a9a9; ">
                                              {{activity.title}}
                                              </div>
                                              <div class="col-md-1">
                                                <!-- <h6 style="color:#a9a9a9">MAR</h6> -->
                                                <p style="color: #BCBFC8;">{{month}}</p>
                                                   <hr style="border-bottom:1px solid red; color: #707070; width:50px">
                                                <p style="color: #000;">{{day}}</p>
                                              </div>
                                          </div>
                                        </router-link>
                                        <hr>
                                       <div class="flexBox" style="display:flex; justify-content:space-between">
                                           <button  :data-target="'#collapseOne' +index"  
                                          data-toggle="collapse"  
                                          aria-expanded="true" 
                                          aria-controls="collapseOne"
                                          style="background-color:#fff; border:none; color:#97c149"
                                           >INDICATORS</button>

                                            <button  :data-target="'#collapseOne' +index"  
                                          data-toggle="collapse"  
                                          aria-expanded="true" 
                                          aria-controls="collapseOne"
                                          style="background-color:#fff; border:none; color:#97c149"
                                           ><img src="../../public/images2/arrow.png" alt="" style="width: 20px; height:10px"></button>
                                       </div>
                                     
                                    </div>

                                    <div :id="'collapseOne'+index" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion" >
                                      <div class="card-body">
                                      <div v-if="typeof activity.indicators !== 'undefined'">
                                        <div class="graph">
            
                                                <div class="row">
                                                    <div class="col-md-12">

                                                    <div class="row" style="text-align:center">
                                                        <div class="col-md-3">
                                                            <v-chart class="chart" :option="Ghana" />
                                                            <h5>Ghana</h5>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <v-chart class="chart" :option="Ethiopia" />
                                                            <h5>Ethiopia</h5>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <v-chart class="chart" :option="Cameroon" />
                                                            <h5>Cameroon</h5>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <v-chart class="chart" :option="Madagascar" />
                                                            <h5>Madagascar</h5>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                    <div class="graphImg" id="map" style="margin-top:25px">
                                                        <v-chart class="bar" :option="graphBar" />
                                                    </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                                <div class="gallery"  v-if="activeTab === 'gallery'">
                                  <div class="row">
                                    <div class="col-md-4" v-for="gallery in galleries" :key="gallery.id">
                                      <img :src="ImgUrl+gallery.fileName" alt="" id="galleryImg">
                                    </div>
                                  </div>
                                  
                                </div>
                                <div class="istory"  v-if="activeTab === 'istory'">
                                  <div >
                                    <div>
                                        <div class="container">
                                        <h4 >Vidoes</h4>
                                            <hr class="hr">

                                        <div class="row" id="tweets">

                                        <div class="col-md-4" v-for="istoreVid in vidoes" :key="istoreVid.id">
                                          <router-link :to="'/istoryv/' +istoreVid.id" style="text-decoration:none; color:#707070">
                                            <figure class="figure">
                                            <img :src="ImgUrl+istoreVid.images" class="figure-img img-fluid rounded">
                                            <figcaption class="figure-caption">
                                            <h5 style="color:#000">{{istoreVid.title}}</h5>
                                                {{istoreVid.description}}</figcaption>
                                          <br><br>
                                            </figure>
                                          </router-link>
                                        </div>
                                      
                                        </div>
                                        </div>
                                        <div class="showMore">
                                      
                                        </div>
                                    </div>
                                <div >
                              <div class="container">
                              <h4 class="faq">Stories</h4>
                                  <hr class="hr">

                              <div class="row" id="tweets">
                              <div class="col-md-6" v-for="istore in istories" :key="istore.id" style="padding:10px">
                                 <router-link :to="'/istory/'  +istore.id" style="text-decoration:none; color:#707070">
                                    <div class="row" id="prev">
                                  <div class="col-md-7" style="text-align:left; padding:20px" >
                                      <h5>{{istore.title}}</h5>
                                      <p>{{istore.description}}</p>
                                  </div>
                                  <div class="col-md-5" id="vidoePrev">
                                  <img v-if="ImgUrl" :src='ImgUrl+istore.images' alt="" id="img">
                                  </div>
                                  </div>
                                 </router-link>
                              </div>
                            
                              </div>
                              </div>
                              <div class="showMore">
                           
                              </div>
                          </div>

                          </div>
                                </div>
                              </div>
                      
                        </div>
                      </div>
                      </div>




    </div>
    <Footer></Footer>
  </div>

  </div>
</template>

<script>
// @ is an alias to /src


import Header from '../components/Navbar';
import Footer from '../components/footer'
import baseUrl from '../baseUrl'
import moment from 'moment'
import appConfig from "../appConfig";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart } from "echarts/charts";
import {GridComponent} from "echarts/components";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);


export default {
  name: 'Home',
  components: {
    Header,
   Footer,
    VChart

  },
   provide: {
    [THEME_KEY]: "white"
  },
  data() {  
     return{
    activeTab:'activities',
    detail:[],
    component:[],
    activitiesData:[],
    istories:[],
    activitylaglng:[],
    galleries:[],
    ImgUrl: appConfig.baseUrl,
    vidoes:[],
    month:{},
    day:{},
    id: this.$route.params.id,
    maleGhana:{},
    indicators:[],
    graphDatas:[],  
    ghanaApi:[],
    ethiopiaApi:[],
    cameroonApi:[] ,
    madagascarApi:[],
    achievedBarApi:[],
    expectedBarApi:[],
     }
  },

   methods:{
         home(){
          baseUrl.get(`/api/country/${this.id}`)
          .then((res)=>{
            this.detail = res.data
             this.activitiesData = res.data.activities
              this.istories = res.data.istories
               this.activitylaglng = res.data.activityMap
               this.galleries = res.data.gallery
            
              
           
              
             this.activitiesData.forEach(item => item.indicators ? this.indicators.push(item.indicators): null)
         

                res.data.activities.map(el =>{
                const Month =  moment(el.startDate).format('MMMM');
                 const Day =  moment(el.startDate).format('DD');
                this.month = Month
                this.day = Day

                         
                          this.ghanaApi = [
                            {value:this.indicators[0].femaleGhana, name:"Female"},
                            {value:this.indicators[0].maleGhana, name:"Male"}
                            ]
                           this.ethiopiaApi = [
                                  {value: this.indicators[0].femaleEthiopia, name:"Female"},
                                {value: this.indicators[0].maleEthiopia, name:"Male"}
                            ]
                            this.cameroonApi = [
                                {value: this.indicators[0].femaleCameroon, name:"Female"},
                                {value: this.indicators[0].maleCameroon, name:"Male"}
                                
                            ]

                          this.madagascarApi = [
                              {value: this.indicators[0].femaleMadagascar, name:"Female"},
                                {value: this.indicators[0].maleMadagascar, name:"Male"}
                                
                            ]

                          this.achievedBarApi = [
                                {value: this.indicators[0].achievedGhana, name:"Male"},
                                {value: this.indicators[0].achievedEthiopia, name:"Female"},
                                {value: this.indicators[0].achievedCameroon, name:"Female"},
                                {value: this.indicators[0].achievedMadagascar, name:"Female"},
                          ]

                          this.expectedBarApi = [
                                {value: this.indicators[0].expectedGhana, name:"Male"},
                                {value: this.indicators[0].expectedEthiopia, name:"Female"},
                                {value: this.indicators[0].expectedCameroon, name:"Female"},
                                {value: this.indicators[0].expectedMadagascar, name:"Female"},
                          ]
                
               })
          }).catch((err)=>{
            console.log(err)
          })
        },

       sort(id){
      
         baseUrl.get(`/api/country/${this.id}`)
         .then((res)=>{

          this.activitiesData = res.data.activities.filter(el=>el.theme == id.target.value)
         
         
          this.ghanaApi = [
                            {value:this.indicators[0].femaleGhana, name:"Female"},
                            {value:this.indicators[0].maleGhana, name:"Male"}
                            ]
                           this.ethiopiaApi = [
                                  {value: this.indicators[0].femaleEthiopia, name:"Female"},
                                {value: this.indicators[0].maleEthiopia, name:"Male"}
                            ]
                            this.cameroonApi = [
                                {value: this.indicators[0].femaleCameroon, name:"Female"},
                                {value: this.indicators[0].maleCameroon, name:"Male"}
                                
                            ]

                          this.madagascarApi = [
                              {value: this.indicators[0].femaleMadagascar, name:"Female"},
                                {value: this.indicators[0].maleMadagascar, name:"Male"}
                                
                            ]

                          this.achievedBarApi = [
                                {value: this.indicators[0].achievedGhana, name:"Male"},
                                {value: this.indicators[0].achievedEthiopia, name:"Female"},
                                {value: this.indicators[0].achievedCameroon, name:"Female"},
                                {value: this.indicators[0].achievedMadagascar, name:"Female"},
                          ]

                          this.expectedBarApi = [
                                {value: this.indicators[0].expectedGhana, name:"Male"},
                                {value: this.indicators[0].expectedEthiopia, name:"Female"},
                                {value: this.indicators[0].expectedCameroon, name:"Female"},
                                {value: this.indicators[0].expectedMadagascar, name:"Female"},
                          ]
            
         }).catch((err)=>{
           console.log(err)
         })
      },
    
        IstoryVideo(){
          baseUrl.get('/api/getIstories/video')
          .then((res)=>{
              this.vidoes = res.data
          })
          .catch((err)=>{
              console.log(err)
          })
      },
     
    },
     computed:{
         graphBar() {
    //   let that = this;
                    let optionBar = {
                        legend:{
                            show:true
                        },
                 xAxis: {
                        data: [ 'Ghana', 'Ethiopia', 'Cameroon', 'Madagascar']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                        name:'Expected',
                        type: 'bar',
                        data:this.expectedBarApi,
                             emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        },
                           {
                        name:'Achieved',
                        type: 'bar',
                        data:this.achievedBarApi,
                             emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        }
                    ]
                    }
                    return optionBar;
    },

       Ghana() {
                    var colorPalette = ['#83b32a','#2e4405']
                    let ghanaPie = {
                            title: {
                        left: "center"
                        },
                        tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },
                        legend: {
                        orient: "vertical",
                        left: "left",
                         data: [
                            // "Cameroon",
                          
                        ]
                        },
                        series: [
                        {
                            name: "Ghana",
                            type: "pie",
                            radius: "55%",
                            center: ["50%", "60%"],
                            data:this.ghanaApi,
                            
                            //  [
                            //   { value: 123, name: "Male" },
                            //       { value: 12, name: "Female" },
                            //  ],
                            emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)"
                            }
                            }
                        }
                        ],
                        color: colorPalette,
                    }
                    return ghanaPie;
    },
       Ethiopia() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let ethiopiaPie = {
                           title: {
                                left: "center"
                                },
                                tooltip: {
                                trigger: "item",
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                                },
                                legend: {
                                orient: "vertical",
                                left: "left",
                                data: [
                                    // "Ethiopia",
                                
                                ]
                                },
                                series: [
                                {
                                    name: "Ethiopia",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["50%", "60%"],
                                    data:this.ethiopiaApi,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                    }
                                }
                                ],
                                 color: colorPalette,
                    }
                    return ethiopiaPie;
    },
       Cameroon() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let cameroonPie = {
                            title: {
                                left: "center"
                                },
                                tooltip: {
                                trigger: "item",
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                                },
                                legend: {
                                orient: "vertical",
                                left: "left",
                                data: [
                                    // "Ethiopia",
                                    // "F",
                                    // "Ethiopia",
                                    // "Madagascar",
                                ]
                                },
                                series: [
                                {
                                    name: "Cameroon",
                                    type: "pie",
                                    radius: "55%",
                                    center: ["40%", "60%"],
                                    data:this.cameroonApi,
                                    emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: "rgba(0, 0, 0, 0.5)"
                                    }
                                    }
                                }
                                ],
                                 color: colorPalette,
                    }
                    return cameroonPie;
    },
        Madagascar() {
    //   let that = this;
    var colorPalette = ['#83b32a','#2e4405']
                    let madagascarPie = {
                                     title: {
                                        left: "center"
                                        },
                                        tooltip: {
                                        trigger: "item",
                                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                                        },
                                        legend: {
                                        orient: "vertical",
                                        left: "left",
                                        data: [
                                            // "Ghana",
                                            // "Cameroon",
                                            // "Ethiopia",
                                            // "Madagascar",
                                        ]
                                        },
                                        series: [
                                        {
                                            name: "Madagascar",
                                            type: "pie",
                                            radius: "55%",
                                            center: ["50%", "60%"],
                                            data:this.madagascarApi,
                                            emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor: "rgba(0, 0, 0, 0.5)"
                                            }
                                            }
                                        }
                                        ],
                                         color: colorPalette,
                    }
                    return madagascarPie;
    }
 
    },

  mounted() { 
    this.home(),
    this.IstoryVideo()
  },
}
</script>
<style scoped>
#ghana{
  font-family: 'Roboto', sans-serif; 
  color: #707070;
}
.faq{
  margin-top: 170px;
 margin-left: 50px;
 margin-bottom: 10px;
 font-family: 'Roboto', sans-serif; 
 color: #707070;
}
.gray{
  border-top: 1px solid rgb(173, 173, 173);
  background-color: #f5f7fa;
  font-family: 'Roboto', sans-serif; 
  color: #707070;
}
#row{
  padding: 50px;
 
}
#tab{
 box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: #707070;
}
#img{
  width: 200px;
  height: 200px;
   border-radius: 10px;
  object-fit: contain;
}
.group{
  display: flex;
  justify-content: center;
}
li{
  background-color:#f5f7fa
}
.active{
  border-top: 3px solid #97c149;
  background-color: #fff;
}
span{
  color: #97c149;
  margin-left: 10px;
}

.nav-item{
width: 270px;
}
#btn{
  border-radius: 40px;
  background-color: #fff;
  color: #000;
  border: 1px solid rgb(190, 190, 190);
  margin-left: 20px;
}
hr{
  border-top: 1px solid  rgb(190, 190, 190);
}

/*Gallery */
#galleryImg{
  width: 350px;
  height: 250px;
  object-fit: contain;
  padding: 5px;
}
/*end here*/

.container{
background-color: rbg(236, 235, 235);
}
#tweets{
padding-left: 100px;
padding-right: 100px;
padding-bottom: 20px;
margin-top: 40px;
}
.col-md-4{
border-radius: 10px;
position: relative;

}
small{
padding: 20px;
}
.twiter{
    position: absolute;
    bottom: 370px;
    right: 140px;
  }
.figure{
background-color: rgb(255, 255, 255);
border-radius: 10px;
}
.figure-caption{
padding: 20px;
}
.showMore{
text-align: center;
padding: 50px;
}
.showMore button{
border: 1px solid rgb(170, 165, 165);
padding: 10px;
widows: 200px;
border-radius: 10px;
color: rgb(170, 165, 165);
}
#vidoePrev{
border-radius: 10px;
}
#prev{
background-color: #fff;
border-radius: 10px;
}
.col-md-6{
padding-right: 50px;
}
.graph{
      background-color: #fff;
      /* padding: 150px; */
      margin-top: 50px;
  }

.chart {
    height: 200px;
}
.bar{
    height: 600px;
}
</style>